<template>
  <en-drawer title="业务支出" :model-value="modelValue" @close="$emit('update:model-value', false)" :size="1200">
    <en-table :data="form.data.expenses" :loading="form.loading">
      <en-table-column label="操作" width="80">
        <template #default="{ row, $index }: { row: EnocloudServiceDefinitions['ServiceExpenseDto']; $index: number }">
          <en-button type="primary" link @click="form.expenses.discard.click($index)">删除</en-button>
        </template>
      </en-table-column>
      <en-table-column label="支出类别">
        <template #default="{ row }: { row: EnocloudServiceDefinitions['ServiceExpenseDto'] }">
          <en-input v-model="row.type"></en-input>
        </template>
      </en-table-column>
      <en-table-column label="支出费用">
        <template #default="{ row }: { row: EnocloudServiceDefinitions['ServiceExpenseDto'] }">
          <en-input-number v-model="row.amount" :min="0"></en-input-number>
        </template>
      </en-table-column>
    </en-table>

    <template #footer>
      <en-button @click="footer.add.click">新增</en-button>
      <en-button @click="footer.cancel.click">取消</en-button>
      <button-ajax :method="footer.confirm.click">确定</button-ajax>
    </template>
  </en-drawer>
</template>

<script lang="ts">
export default factory({
  props: {
    modelValue: Boolean,
    data: Object as PropType<EnocloudServiceDefinitions['ServiceDto']>
  },

  emits: {
    'update:model-value': (value: boolean) => typeof value === 'boolean',
    confirm: () => true
  },

  watch: {
    modelValue: {
      handler(visible) {
        if (visible) {
          this.form.get()
        } else {
          this.form.init()
        }
      }
    }
  },

  config: {
    children: {
      form: {
        ajax: {
          get: {
            action: 'GET /enocloud/service/:serviceId',
            data: 'object',
            init: true,
            loading: true,
            params(params) {
              params.paths = [this.data?.id]
            }
          },
          submit: {
            action: 'PUT /enocloud/service',
            loading: true,
            params(params) {
              params.payload = this.form.data
              params.payload.expenses = this.form.data.expenses.filter((item) => !!item.type)
            }
          }
        },
        children: {
          expenses: {
            discard: {
              click(index: number) {
                this.form.data.expenses?.splice(index, 1)
              }
            }
          }
        }
      },
      footer: {
        children: {
          cancel: {
            click() {
              this.emit('update:model-value', false)
            }
          },
          add: {
            click() {
              this.form.data.expenses.push({ amount: 0 })
            }
          },
          confirm: {
            async click() {
              await this.form.submit()
              this.emit('update:model-value', false)
              this.emit('confirm')
            }
          }
        }
      }
    }
  }
})
</script>
